<script setup>
const openSidebar = ref(false);

const sidebarToggle = () => {
	openSidebar.value = !openSidebar.value;
};

const menu_items = ref([
	{ path: "/top-features/", label: "TOP FEATURES" },
	{ path: "/plans/", label: "PRICING" },
	{ path: "/broker-integration/", label: "BROKERS" },
	{ path: "/about/", label: "ABOUT" },
	{ path: "/blog/", label: "BLOG" }
]);

const route = useRoute();
watch(
	() => route.path,
	() => {
		openSidebar.value = false;
	},
	{ deep: true, immediate: true }
);

onMounted(() => {
	openSidebar.value = false;
});
</script>

<template>
	<div id="sidebar-nav-container">
		<a
			class="live-demo"
			href="https://stockstotrade.info/STTlive-reg?utm_source=blog&amp;utm_medium=ed_mention&amp;utm_campaign=UU5hdz"
		>
			<div id="live-indicator">
				<div class="blobs-container">
					<div class="blob red"></div>
					<div class="livebox">LIVE</div>
				</div>
			</div>
		</a>
		<div
			id="sidebar-nav"
			:class="`sidenav ${openSidebar ? 'open-sidenav' : ''}`"
		>
			<div class="left-bar">
				<div
					class="left-bar-logo"
					onclick="location.href = '/';"
				>
					s
					<span>t</span>
					t
				</div>

				<button
					class="menu-btn"
					@click="sidebarToggle"
				>
					<img
						src="/images/leftbar-close.svg"
						id="left-bar-close-btn"
					/>
					<div class="more">HIDE</div>
				</button>
			</div>
			<div id="siderbar-nav-container">
				<div id="sidebar-long-logo">
					<NuxtLink to="/"><img src="/images/sttlogo_left.svg" /></NuxtLink>
				</div>
				<div id="sidebar-page-links">
					<NuxtLink
						class="router-link"
						:key="i"
						v-for="(item, i) in menu_items"
						:to="item.path"
					>
						{{ item.label }}
						<span
							class="active-link"
							@click="openSidebar = false"
							v-if="route.path === item.path"
						></span>
					</NuxtLink>
				</div>
				<div id="sidebar-authen-links">
					<a
						href="https://stockstotrade.info/STTlive-reg?utm_source=blog&amp;utm_medium=ed_mention&amp;utm_campaign=3hk0yW"
					>
						Live Demo
					</a>

					<a href="https://orders.stockstotrade.com/cp/sign_in">Log In</a>
					<a href="https://support.stockstotrade.com/hc/en-us">Support</a>
				</div>
			</div>
		</div>
		<div class="left-bar">
			<div
				class="left-bar-logo"
				onclick="location.href = '/';"
			>
				s
				<span>t</span>
				t
			</div>

			<button
				class="menu-btn"
				@click="sidebarToggle"
			>
				<img
					src="/images/leftbar-open.svg"
					id="left-bar-open-btn"
				/>
			</button>
			<div class="more">MORE</div>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
