<template>
	<div>
		<Head>
			<link
				rel="preconnect"
				href="https://fonts.googleapis.com"
			/>
			<link
				rel="preconnect"
				href="https://fonts.gstatic.com"
				crossorigin
			/>
			<link
				href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap"
				rel="stylesheet"
			/>

			<link
				rel="stylesheet"
				href="/styles/main.css"
			/>
		</Head>
		<stt-sidebar></stt-sidebar>

		<div
			id="right-content"
			class="error-page"
		>
			<div
				id="top-menu"
				class="content-padding"
			>
				<div><a href="https://orders.stockstotrade.com/cp/sign_in">LOGIN</a></div>
				<div>
					<a href="https://university.stockstotrade.com/wp-login.php">
						University Login
					</a>
				</div>
				<div id="top-phone">
					<a href="tel:1-866-345-4576">
						<img
							src="/images/phone.svg"
							id="icon-nav-phone"
						/>
						1 (866) 345-4576
					</a>
				</div>
			</div>

			<slot />

			<stt-footer></stt-footer>
		</div>
	</div>
</template>

<script>
export default {
	head() {
		return {
			bodyAttrs: {
				class: "full-width-content"
			},
			link: [{ rel: "icon", type: "image/x-icon", href: "/favicon.ico" }]
		};
	}
};
</script>

<style lang="scss" scoped>
.error-page {
	background-color: #262930 !important;
}

#right-content.error-page #content {
	background-color: #262930 !important;
}
</style>
